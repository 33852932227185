module.exports = {
  ENV: 'prod',
  APP_NAME: 'runeo',
  END_POINT: 'https://ssm.services.apihf.com',
    API_KEY: 'tZikzVTX731Vbxdk6d5Uua2EevtC6Cus3pRKSwre',
    BRAND_KEY: '4424c5d1-0924-4c86-8901-40bc42fcfa25',
  CLIENT_ID: '2hcnhauq2qob6f67q6ikcb7of1',
  USER_POOL_ID: 'eu-west-1_HIn9p5IYR',
  HEADER_MESSAGE_SIZE: 41,
  AGENTS_GROUP: 'RUNEO_AGENTS',
}